.quote-container {
    width: 100%;
    padding: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.quote-container .HEADER {
    font-weight: 900;
    font-size: 35px;
    line-height: 131.5%;
    text-align: center;
    margin-bottom: 25px;
}

.quote-container .TRUST_BLOCK_QUOTE {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 131.5%;
    text-align: center;
    margin-bottom: 25px;
    position: relative;
    width: 600px;
}