.misc-container-01 {
    width: 100%;
    padding: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.misc-container-01 h1{
    width: 60%;
    font-weight: 900;
    font-size: 48px;
    line-height: 131.5%;
    margin-bottom: 30px;
}

.misc-container-01 > div > div {
    width: 100%;
}

.misc-container-01 > div  {
    width: 60%;
}

.misc-container-01 .MISC_BLOCK_QUESTION {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 131.5%;
    text-align: left;
    margin-bottom: 10px;
}

.misc-container-01 .MISC_BLOCK_ANSWER {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 131.5%;
    text-align: left;
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 2px solid black;
}

.misc-button-01 {
    width: 60%;
}

.misc-button-01 button {
    font-size: 16px;
    padding: 20px;
    width: 167px;
    height: 61px;
    background: #FFFFFF;
    border: 1px solid #000000;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
    border-radius: 3px;
    text-align: center;
    margin: 30px 0 0 0;
    cursor: pointer;
}